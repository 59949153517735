$(document).ready(function () {

    /*
    | -------------------------------------------
    | FAKTURA VAT
    | -------------------------------------------
    */
    let fieldIsFv = $('#fieldIsFv');

    if (fieldIsFv.length) {
        let isFvBox = $('#fvFields');
        let fvIsCompany = $('#fieldIsCompany');
        let fvFirstName = $('#label_fieldFvFirstName');
        let fvLastName = $('#label_fieldFvLastName');
        let fvCompanyName = $('#label_fieldFvCompanyName');
        let fvNip = $('#label_fieldFvNip');

        if (fieldIsFv.is(':checked')) {
            isFvBox.removeClass('d-none').find('input.is-required').attr('required', true);
            if (fvIsCompany.is(':checked')) {
                fvFirstName.hide().find('input').attr('required', false);
                fvLastName.hide().find('input').attr('required', false);
                fvCompanyName.show().find('input').attr('required', true);
                fvNip.show().find('input').attr('required', true);
            } else {
                fvFirstName.show().find('input').attr('required', true);
                fvLastName.show().find('input').attr('required', true);
                fvCompanyName.hide().find('input').attr('required', false);
                fvNip.hide().find('input').attr('required', false);
            }
        } else {
            isFvBox.addClass('d-none').find('input.is-required').attr('required', false);
        }

        

        fieldIsFv.on('change', function () {
            let obj = $(this);
    
            if (obj.is(':checked')) {
                isFvBox.removeClass('d-none').find('input.is-required').attr('required', true);
                if (fvIsCompany.is(':checked')) {
                    fvFirstName.hide().find('input').attr('required', false);
                    fvLastName.hide().find('input').attr('required', false);
                    fvCompanyName.show().find('input').attr('required', true);
                    fvNip.show().find('input').attr('required', true);
                } else {
                    fvFirstName.show().find('input').attr('required', true);
                    fvLastName.show().find('input').attr('required', true);
                    fvCompanyName.hide().find('input').attr('required', false);
                    fvNip.hide().find('input').attr('required', false);
                }
            } else {
                isFvBox.addClass('d-none').find('input.is-required').attr('required', false);
            }
        });

        fvIsCompany.on('change', function() {
            let obj = $(this);
            if (obj.is(':checked')) {
                fvFirstName.hide().find('input').attr('required', false);
                fvLastName.hide().find('input').attr('required', false);
                fvCompanyName.show().find('input').attr('required', true);
                fvNip.show().find('input').attr('required', true);
            } else {
                fvFirstName.show().find('input').attr('required', true);
                fvLastName.show().find('input').attr('required', true);
                fvCompanyName.hide().find('input').attr('required', false);
                fvNip.hide().find('input').attr('required', false);
            }
        })
    }


    /*
    | -------------------------------------------
    | INNY ADRES WYSYŁKI
    | -------------------------------------------
    */
    let fieldDiffAddress = $('#fieldIsDiff');

    if (fieldDiffAddress.length) {
        let isDiffBox = $('#diffFields');

        if (fieldDiffAddress.is(':checked')) {
            isDiffBox.removeClass('d-none').find('input.is-required').attr('required', true);
        } else {
            isDiffBox.addClass('d-none').find('input.is-required').attr('required', false);
        }

        fieldDiffAddress.on('change', function () {
            let obj = $(this);
    
            if (obj.is(':checked')) {
                isDiffBox.removeClass('d-none').find('input.is-required').attr('required', true);
            } else {
                isDiffBox.addClass('d-none').find('input.is-required').attr('required', false);
            }
        });
    }
})