import slick from 'slick-carousel';
import fancybox from '@fancyapps/fancybox';
import {popover, tooltip} from 'bootstrap';
import cookie from 'jquery.cookie';

$(document).ready(function(){
    //region menu mobilne 
    var mobileMenu = $('.navmobile');
    $('#mobileMenuOpen').on('click', function () {
        mobileMenu.addClass('active');
    });
    $('#mobileClose').on('click', function () {
        mobileMenu.removeClass('active');
    });
    //endregion

    //region menu desktop
    (function () {
        var navDesktop = $(".nav-desktop"),
            navDesktopContainer = $(".nav-desktop-container"),
            navDesktopHeight = navDesktop.height(),
            navDesktopOffset = navDesktopContainer.offset().top,
            scrollPosition = $("body").scrollTop();

        navDesktopContainer.height(navDesktopHeight);

        if (scrollPosition > navDesktopOffset) {
            navDesktop.addClass("nav-desktop--fixed");
        } else {
            navDesktop.removeClass("nav-desktop--fixed");
        }

        $(window).on("scroll", function () {

            navDesktopOffset = navDesktopContainer.offset().top;
            scrollPosition = $("body").scrollTop();

            if (scrollPosition > navDesktopOffset) {
                navDesktop.addClass("nav-desktop--fixed");
            } else {
                navDesktop.removeClass("nav-desktop--fixed");
            }
        })
    })();
    //endregion

    /*
     * menu link active helper
     */
    $(".nav-desktop .sub-item.active").parents(".sub-item").addClass("active");
    $(".nav-desktop .sub-item.active").parents(".nav-item").addClass("active");
    $(".navi-item-link.active").parents("li").children("a").addClass("active");

    /* slider */
    $(".main-slider__slider").slick({
        autoplay: true,
        autoplaySpeed: 8000,
        pauseOnFocus: false,
        pauseOnHover: false,
        speed: 1500,
        prevArrow: '<div class="prev"><i class="material-icons">navigate_before</i></div>',
        nextArrow: '<div class="next"><i class="material-icons">navigate_next</i></div>',
        dots: true,
        centerMode: true,
        fade: true,
    });

    /* >>> slider kategorii */
    $(".categories__slider").slick({
        autoplay: true,
        autoplaySpeed: 3000,
        pauseOnFocus: false,
        pauseOnHover: false,
        speed: 1500,
        prevArrow: '<div class="prev"><i class="material-icons">navigate_before</i></div>',
        nextArrow: '<div class="next"><i class="material-icons">navigate_next</i></div>',
        dots: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 990,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 766,
                settings: {
                    slidesToShow: 2
                }
            }
        ]
    });
    /* slider kategorii <<< */

    /* >>> slider partnerów */
    $(".partners__slider").slick({
        autoplay: true,
        autoplaySpeed: 500,
        pauseOnFocus: false,
        pauseOnHover: false,
        speed: 1500,
        arrows: false,
        dots: false,
        slidesToShow: 8,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1199,
                settings: {
                    slidesToShow: 6
                }
            },
            {
                breakpoint: 990,
                settings: {
                    slidesToShow: 5
                }
            },
            {
                breakpoint: 766,
                settings: {
                    slidesToShow: 4
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 420,
                settings: {
                    slidesToShow: 2
                }
            }
        ]
    });
    /* slider partnerów <<< */

    /* >>> slider boxów */
    $(".box__slider").slick({
        autoplay: true,
        autoplaySpeed: 3000,
        pauseOnFocus: true,
        pauseOnHover: true,
        speed: 1500,
        arrows: false,
        dots: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 990,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 766,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    });
    /* slider boxów <<< */

    $(".product__gallery-slider").slick({
        autoplay: true,
        pauseOnFocus: false,
        pauseOnHover: false,
        speed: 1500,
        autoplaySpeed: 3000,
        arrows: false,
        dots: false,
        slidesToShow: 5,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1199,
                settings: {
                    slidesToShow: 6
                }
            },
            {
                breakpoint: 990,
                settings: {
                    slidesToShow: 5
                }
            },
            {
                breakpoint: 766,
                settings: {
                    slidesToShow: 3
                }
            }
        ]
    });
    
    /* 
     * menu link active helper
     */
    $(".navbar .dropdown-item.active").parents("li").addClass("active");
    $(".navi-item-link.active").parents("li").children("a").addClass("active");
    
    /* popover
     *  
     */
    $(function () {
        $('[data-toggle="popover"]').popover()
    });

    $(function () {
        $('[data-toggle="tooltip"]').tooltip()
    });
    
    /* FANCYBOX */
     $("a.fancybox").fancybox({
         loop : true,
         keyboard : true,
     });
    
    /* COOKIE */
    if($("#cookieInfo").length){
        var cookie = $.cookie("cookieinfo");
        if(cookie === '0'){
            $("#cookieInfo .cookieClose").on('click', function(){
                $.cookie("cookieinfo", '1', {path:'/', expires : 3650});
                $("#cookieInfo").animate({
                    'margin-top': - $("#cookieInfo").outerHeight(true)
                }, 800, function(){
                    $("#cookieInfo").remove();
                });

            })
        }
    }

    if ($('select[name="count_on_page"]').length){
        $('body').on('change', 'select[name="count_on_page"]', function(e){
            var ppp = $(this).val();
            var ajaxLoad = $(".ajax-load"),
                preloader = $(".preloader");

            e.preventDefault();
            preloader.addClass("preloader--active");

            ajaxLoad.load(ppp + " .ajax-loaded-data", function () {
                scalePic(4, 3);
                $("html, body").animate({
                    scrollTop: ajaxLoad.offset().top - 30
                }, 300, function () {
                    preloader = $(".preloader");
                    preloader.removeClass("preloader--active");
                });
                scalePic(4, 3);
                window.history.pushState("", "", ppp);
            });
        });
    }

    if ($('select[name="producent"]').length){
        $('body').on('change', 'select[name="producent"]', function(e){
            var link = $(this).val();
            var ajaxLoad = $(".ajax-load"),
                preloader = $(".preloader");
            e.preventDefault();
            preloader.addClass("preloader--active");
            ajaxLoad.load(link + " .ajax-loaded-data", function () {
                scalePic(4, 3);
                $("html, body").animate({
                    scrollTop: ajaxLoad.offset().top - 30
                }, 300, function () {
                    preloader = $(".preloader");
                    preloader.removeClass("preloader--active");
                });
                scalePic(4, 3);
                window.history.pushState("", "", link);
            });
        });
    }

    if ($('select[name="ord_select"]').length){
        $('body').on('change', 'select[name="ord_select"]', function(e){
            var link = $(this).val();
            var ajaxLoad = $(".ajax-load"),
                preloader = $(".preloader");
            e.preventDefault();
            preloader.addClass("preloader--active");
            ajaxLoad.load(link + " .ajax-loaded-data", function () {
                scalePic(4, 3);
                $("html, body").animate({
                    scrollTop: ajaxLoad.offset().top - 30
                }, 300, function () {
                    preloader = $(".preloader");
                    preloader.removeClass("preloader--active");
                });
                scalePic(4, 3);
                window.history.pushState("", "", link);
            });
        });
    }

    (function() {
        $('body').on('click', '#change_prod_view', function () {

            if ($.cookie('viewStyleTiles') == '1') {
                $.cookie("viewStyleTiles", '0', {path:'/', expires : 3650});
            } else {
                $.cookie("viewStyleTiles", '1', {path:'/', expires : 3650});
            }

            var ajaxLoad = $(".ajax-load"),
                preloader = $(".preloader"),
                changeViewButton = $("#change_prod_view"),
                link = changeViewButton.data('url');

            preloader.addClass("preloader--active");

            ajaxLoad.load(link + " .ajax-loaded-data", function () {
                scalePic(4, 3);
                $("html, body").animate({
                    scrollTop: ajaxLoad.offset().top - 30
                }, 300, function () {
                    preloader = $(".preloader");
                    preloader.removeClass("preloader--active");
                });
                scalePic(4, 3);
                window.history.pushState("", "", link);
            });
        })

    })();
    
    $(".navbar-toggler").click(function(){
        if($(this).hasClass("collapsed")) {
            $(this).removeClass("collapsed");
            $(".mainnav").addClass("hover");
        }else{
            $(this).addClass("collapsed");
            $(".mainnav").removeClass("hover");
        }
    });

    /* >>> skalowanie iframe 16:9 */
    $(window).on('resize load DOMContentLoaded', function() {
        var scalIframe169 = $('.text iframe');
        if (scalIframe169.length) {
            scalIframe169.each(function () {
                $(this).height(($(this).width() / 16) * 9);
            })
        }
    });
    /* >>> skalowanie iframe 16:9 <<< */

    //region skalowanie zdjęć
    (function () {
        $(window).on('resize load DOMContentLoaded', function() {
            scalePic(16, 6);
            scalePic(16, 7);
            scalePic(16, 9);
            scalePic(16, 10);
            scalePic(4, 3);
        });
    })();
    //endregion

    //region Stronicowanie Ajaxem
    (function () {
        var ajaxLoad = $(".ajax-load"),
            preloader = $(".preloader");

        if (ajaxLoad.length) {
            ajaxLoad.on("click", "a.page-link", function (e) {
                e.preventDefault();
                preloader.addClass("preloader--active");

                var page = $(this).attr("href");


                ajaxLoad.load(page + " .ajax-loaded-data", function () {
                    scalePic(4, 3);
                    $("html, body").animate({
                        scrollTop: ajaxLoad.offset().top - 30
                    }, 300, function () {
                        preloader = $(".preloader");
                        preloader.removeClass("preloader--active");
                    });
                    window.history.pushState("", "", page);
                });

            })

        }

    })();
    //endregion

    /* >>> potwierdzenie akcji */
    if((".confirm").length){
        $(".confirm").on('click', function(){
            if(!confirm($(this).data('confirm'))){
                return false;
            }
        });
    }
    /* potwierdzenie akcji <<< */

    //region socialmedia
    (function () {
        var socialmedia = $(".socialmedia-f"),
            docwindow = $(window),
            socialmediaHeight = socialmedia.height(),
            windowHeight = docwindow.height();
        socialmedia.css('top', ((windowHeight - socialmediaHeight)) /2);
        $(window).on("load resize", function () {
            socialmediaHeight = socialmedia.height();
            windowHeight = docwindow.height();
            socialmedia.css('top', ((windowHeight - socialmediaHeight)) /2);
        });
    })();
    //endregion
});

//region funkcja skalująca zdjęcia
function scalePic(width, height) {
    var scalPicture = $(".pic-"+width+"-"+height);

    if (scalPicture.length) {
        scalPicture.each(function () {
            $(this).height(($(this).width() / width) * height);
        })
    }
}
//endregion
