import {toast} from 'bootstrap';
import {debounce} from 'lodash';

(function($) {

    $(document).ready(function() {
        var toastContainer = $('.toast-container');
        var body = $("body");
        var lang = body.data("lang");

        (function () {
            var clientsIsCompany = $('#fieldIsCompany');

            if ($("#warrantyClaimForm").length) {

                var clientsCompanyName = $('#label_fieldCompanyName');
                var clientsNip = $('#label_fieldNip');

                if ( clientsIsCompany.is(':checked') ) {
                    clientsShowCompanyFields();
                } else {
                    clientsShowIndividualFields();
                }

                clientsIsCompany.on('change', function () {
                    if ( $(this).is(':checked') ) {
                        clientsShowCompanyFields();
                    } else {
                        clientsShowIndividualFields();
                    }
                });

                function clientsShowIndividualFields() {
                    clientsCompanyName.hide().find('input').attr('required', false);
                    clientsNip.hide().find('input').attr('required', false);
                }
                function clientsShowCompanyFields() {
                    clientsCompanyName.show().find('input').attr('required', true);
                    clientsNip.show().find('input').attr('required', true);
                }
            }
        })();

        //region obsługa koszyka w menu głównym
        var toast = $('.toast').toast();
        toast.show();
        (function () {
            var addToCartBtn = $(".addToCart"),
                clearCartBtn = $("#clearCart"),
                openCartBtn = $("#openCart"),
                cartCount = $("#cartCountNumber"),
                cartBody = $("#cartBody"),
                inProgress = false,
                id = addToCartBtn.data("id"),
                quantity = addToCartBtn.data("quantity"),
                cartTableLoad = $(".cart-ajax-load");


            /* >>> akcje na podstronie koszyka */
            if (cartTableLoad.length) {
                cartTableLoad.on("keyup", ".change-quantity", debounce(loadCart, 500));
                cartTableLoad.on("click", ".table-cart-remove", function () {
                    var id = $(this).data("id");
                    var action = $(this).data('action');
                    $.when(removeFromCart(id, action)).then(loadCart());

                });
                $("#processOrder").on("click", function (e) {
                    e.preventDefault();
                    checkCartItems($(this).attr("href"));
                })
            }
            /* akcje na podstronie koszyka <<< */


            /* >>> akcje dla koszyka w menu głównym */
            // clearCartBtn.on("click", function () {
            //     clearCart();
            // });

            // openCartBtn.on("click", function () {
            //     getMenuCart();
            // });
            /* >>> akcje dla koszyka w menu głównym <<< */


            $('body').on("click", '.addToCart', function () {
                var that = $(this);
                id = that.data('id');
                quantity = that.data('quantity');
                addtoCart(id, quantity, that)
            });


            function createCartElem(data) {
                return $("<div></div>", {
                    "class": "dropdown-elem d-flex align-items-center justify-content-between",
                    "id": "cartDropdownItem" + data.Id
                }).append(
                    $("<div></div>", {
                        "text": data.Name
                    })
                ).append($("<div></div>", {
                    "class": "text-danger font-weight-bold cursor-pointer ml-3",
                    "text": "x",
                    "data-id": data.Id,
                    on: {
                        click: function () {
                            removeFromCart(data.Id)
                        }
                    }
                }));
            }

            function addtoCart(id, quantity, btn) {
                if (!inProgress && !btn.hasClass('added-to-cart')) {
                    $.ajax({
                        type: "post",
                        url: "/koszyk/add",
                        data: {
                            id: id,
                            quantity: quantity
                        },
                        beforeSend: function() {
                            inProgress = true;
                        },
                        success: function (response) {
                            cartCount.text(response);
                            inProgress = false;

                            var btnText = "dodano do koszyka";
                            if (lang === "EN") {
                                btnText = "added to cart";
                            }
                            if (lang === "DE") {
                                btnText = "wurde dem Warenkorb hinzugefügt";
                            }

                            btn.find("span").text(btnText);
                            btn.find("i").text("done");
                            btn.addClass('added-to-cart');

                            var productName = btn.parents('.products-root').find('.products-item-title').text();

                            var toast = $('<div class="toast">' +
                                    '<div class="toast-header">' +
                                        '<strong class="mr-auto">Bass Tools</strong>' +
                                        '<small class="text-muted">teraz</small>' +
                                        '<button type="button" class="ml-2 mb-1 close" data-dismiss="toast" aria-label="Close">' +
                                            '<span aria-hidden="true">&times;</span>' +
                                        '</button>' +
                                    '</div>' +
                                    '<div class="toast-body">Dodałeś <strong>'+ productName +'</strong> do koszyka.</div>' +
                                '</div>');
                            toast.toast({
                                delay: 6000
                            });
                            toastContainer.append(toast);
                            toast.toast('show');

                            toast.on('hidden.bs.toast', function () {
                                $(this).remove();
                            })
                        }
                    });
                }
            }

            function removeFromCart(id, action) {
                if (!inProgress) {
                    $.ajax({
                        type: "post",
                        url: "/koszyk/remove",
                        data: {
                            id: id,
                            action: action
                        },
                        beforeSend: function() {
                            inProgress = true;
                        },
                        success: function () {
                            inProgress = false;
                            if (action === 'removeDiscount' && $('#discountCodeTr').length) {
                                $('#discountCodeTr').remove();
                            }
                            getMenuCart();
                        }
                    });
                }
            }

            function clearCart() {
                if (!inProgress) {
                    $.ajax({
                        type: "post",
                        url: "/koszyk/clear",
                        beforeSend: function() {
                            inProgress = true;
                        },
                        success: function () {
                            inProgress = false;
                            cartBody.empty();
                            cartCount.text("0");
                        }
                    });
                }
            }

            function getMenuCart() {
                if (!inProgress) {
                    $.ajax({
                        type: "post",
                        url: "/koszyk/load",
                        dataType: "json",
                        beforeSend: function() {
                            inProgress = true;
                            cartBody.empty();
                        },
                        success: function (response) {
                            if (!jQuery.isEmptyObject(response)) {
                                $.each(response, function (i, obj) {
                                    cartBody.prepend(createCartElem(obj));
                                });
                            }
                            cartCount.text(response.length);
                            inProgress = false;
                        }
                    });
                }
            }

            function loadCart() {
                var ajaxLoad = $(".cart-ajax-load"),
                    changeQuantityField = $(".change-quantity"),
                    data = [],
                    preloader = $(".preloader"),
                    target = ajaxLoad.data("target");

                if (changeQuantityField.length) {
                    changeQuantityField.each(function (i, elem) {
                        var that = $(elem);
                        data[i] = {
                            id: that.data("id"),
                            quantity: that.val(),
                            accurancy: that.data("accurancy")
                        }
                    })
                }

                preloader.addClass("preloader--active");


                ajaxLoad.load(target+" .cart-ajax-loaded-data", {
                        data: JSON.stringify(data),
                        action: 'loadCart'
                    },
                    function () {
                        scalePic(4, 3);
                        preloader.removeClass("preloader--active");
                    });
            }

            function checkCartItems(href) {
                var isOK = true;
                $(".table-cart__item").each(function (i, elem) {
                    var that = $(elem),
                        stockValue = parseFloat(that.data("stock"));

                    if (stockValue === 0) {
                        isOK = false;
                        that.addClass("row-error")
                    } else {
                        that.removeClass("row-error")
                    }

                });

                if (isOK) {
                    window.location.href = href;
                } else {
                    var alert = $(".alert-danger");
                    if (!alert.length) {
                        alert = $("<div></div>", {
                            "class": "alert alert-danger mb-4"
                        });
                        alert.insertBefore($(".table"));
                    }
                    alert.text("W koszyku posiadasz produkty których nie mamy obecnie na magazynie. Usuń te produkty z koszyka aby kontynuować.")
                }
            }
        })();
        //endregion

        //region Zmiana formy dostawy
        (function () {
            body.on("change", "#DeliveryField", function () {
                var deliveryId = $(this).val(),
                    ajaxLoad = $(".cart-ajax-load"),
                    preloader = $(".preloader");

                preloader.addClass("preloader--active");

                $.ajax({
                    type: 'post',
                    url: '/zamowienie',
                    data: {
                        deliveryId: deliveryId
                    },
                    success: function() {
                        location.reload();
                    }
                })

                // ajaxLoad.load("/zamowienie .cart-ajax-loaded-data", {
                //         deliveryId: deliveryId
                //     },
                //     function () {
                //         location.reload();
                //         //scalePic(4, 3);
                //         //preloader.removeClass("preloader--active");
                //     });
            })
        })();
        //endregion

        //region Zmiana adresu
        (function () {
            body.on("change", "#fieldAddressId", function () {
                var addressId = $(this).val(),
                    ajaxLoad = $(".cart-ajax-load"),
                    preloader = $(".preloader");

                preloader.addClass("preloader--active");

                ajaxLoad.load("/zamowienie .cart-ajax-loaded-data", {
                        addressId: addressId
                    },
                    function () {
                        scalePic(4, 3);
                        preloader.removeClass("preloader--active");
                    });
            })
        })();
        //endregion

        //region newsletter
        (function () {
            var email = $("#subscribeNewsletterEmail"),
                submit = $("#subscribeNewsletterButton"),
                alertDanger = $("#newsletterAlertDanger"),
                alertSuccess = $("#newsletterAlertSuccess");

            submit.on("click", function () {
                $.ajax({
                    type: "POST",
                    dataType: "json",
                    url: "/Subscribenewsletter",
                    data: {
                        email: email.val()
                    },
                    beforeSend: function () {
                        alertDanger.empty().addClass('d-none');
                        alertSuccess.empty().addClass('d-none');
                    },
                    success: function (response) {
                        if (response['status'] === 'error') {
                            alertDanger.html(response['errorMsg']).removeClass('d-none')
                        }
                        if (response['status'] === 'ok') {
                            alertSuccess.html(response['successMsg']).removeClass('d-none')
                        }
                    }
                });
            })
        })();
        (function () {
            var email = $("#unsubscribeNewsletterEmail"),
                submit = $("#unsubscribeNewsletterButton"),
                alertDanger = $("#newsletterAlertDangerUn"),
                alertSuccess = $("#newsletterAlertSuccessUn");

            submit.on("click", function () {
                $.ajax({
                    type: "POST",
                    dataType: "json",
                    url: "/Wypisz-z-newslettera",
                    data: {
                        email: email.val()
                    },
                    beforeSend: function () {
                        alertDanger.empty().addClass('d-none');
                        alertSuccess.empty().addClass('d-none');
                    },
                    success: function (response) {
                        if (response['status'] === 'error') {
                            alertDanger.html(response['errorMsg']).removeClass('d-none')
                        }
                        if (response['status'] === 'ok') {
                            alertSuccess.html(response['successMsg']).removeClass('d-none')
                        }
                    }
                });
            })
        })();
        //endregion

        //region ratings
        (function () {
            var productsRatingDiv = $(".products__rating--active");
            var inprogress = false;

            productsRatingDiv.on("click", ".products__star--active", function () {
                if (!inprogress) {
                    inprogress = true;
                    var that = $(this);
                    var thisRatingProduct = that.parents(".products__rating--active");
                    var productId = thisRatingProduct.data("product");
                    var ratingDivId = $(".productsRatingId"+productId);
                    var rating = that.data("vote");
                    var waiting = ratingDivId.find(".products__waiting");
                    var votedNumber = ratingDivId.find(".products__votes");

                    $.ajax({
                        type: "POST",
                        data: {
                            ProductId: productId,
                            Rating: rating
                        },
                        dataType: 'json',
                        url: "/rating",
                        beforeSend: function() {
                            waiting.addClass("products__waiting--active");
                            ratingDivId.css("opacity", "0.5");
                        },
                        success: function (response) {
                            if (response) {
                                votedNumber.text("(" + response['RatingVotes'] + ")");

                                ratingDivId.each(function () {
                                    var block = $(this);
                                    var starEmpty = block.find(".products__star-img--empty");
                                    var starfill = block.find(" .products__star-img--fill");

                                    starfill.removeClass("active");
                                    starEmpty.addClass("active");

                                    starfill.each(function (index) {
                                        if (index+1 <= response['RatingAverage']) {
                                            $(this).addClass("active");
                                        }
                                    });
                                });

                                waiting.removeClass("products__waiting--active");
                                inprogress = false;
                                ratingDivId.css("opacity", "1");
                            }
                        }
                    });
                }

            });
        })();
        //endregion

        /* auto register */
        (function () {
            var autoRegisterButton = $("#fieldIsAutoRegister");
            var password = $("#label_fieldPassword");
            var passwordRepeat = $("#label_fieldPasswordRepeat");

            if (autoRegisterButton.length) {
                var isAutoRegister = autoRegisterButton.is(":checked");

                if (isAutoRegister) {
                    showPasswordFields(password, passwordRepeat);
                } else {
                    hidePasswordFields(password, passwordRepeat);
                }

                autoRegisterButton.on("change", function () {
                    isAutoRegister = $(this).is(":checked");
                    if (isAutoRegister) {
                        showPasswordFields(password, passwordRepeat);
                    } else {
                        hidePasswordFields(password, passwordRepeat);
                    }
                });
            }
        })();
        /* auto register END */

        // ticket attachments
        (function () {
            var form = $('#warrantyClaimForm');
            if (form.length) {

                // add new input file attachment
                form.on('change', '.ticket-attachment-file', function () {
                    var ticketAttachment = $('.ticket-attachment');
                    var elem = $(this).parent();

                    if (ticketAttachment.length < 3 && elem.is(':last-child')) {
                        var newAttachment = elem.clone();
                        newAttachment.insertAfter(ticketAttachment.last());
                    }
                });

                // delete input file attachment
                form.on('click', '.delete-ticket-attachment', function() {
                    $(this).parent().remove();
                })
            }
        })();
    });
})(jQuery);

function showPasswordFields(password, passwordRepeat) {
    password.show().find('input').attr('required', 'required');
    passwordRepeat.show().find('input').attr('required', 'required');
}

function hidePasswordFields(password, passwordRepeat) {
    password.hide().find('input').removeAttr('required');
    passwordRepeat.hide().find('input').removeAttr('required');
}

//region funkcja skalująca zdjęcia
function scalePic(width, height) {
    var scalPicture = $(".pic-"+width+"-"+height);

    if (scalPicture.length) {
        scalPicture.each(function () {
            $(this).height(($(this).width() / width) * height);
        })
    }
}
//endregion



